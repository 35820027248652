import React, { useState } from 'react';

const SendPaymentDetails = ({ account }) => {
  const [formData, setFormData] = useState({
    file_number: '',
    payee: '',
    recipient: '',
    mailing_address: ''
  });
  const [formState, setFormState] = useState({
    isSubmitting: false,
    isSubmitted: false,
    error: null
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormState({ ...formState, isSubmitting: true, error: null });

    if (!confirm("Are you sure you want to submit?")) {
      setFormState({ ...formState, isSubmitting: false, isSubmitted: false });
      return;
    }
    
    try {
      const response = await fetch('/collectors/api/update_payment_details', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({
          customer_document_id: account.customer_document.id,
          ...formData
        })
      });

      if (response.ok) {
        setFormState({ isSubmitting: false, isSubmitted: true, error: null });
      } else {
        const data = await response.json();
        alert(data.error);
        setFormState({ isSubmitting: false, isSubmitted: false });
      }
    } catch (e) {
      alert(e.message);
      setFormState({ isSubmitting: false, isSubmitted: false });
    }
  };

  const handleChange = (e) => {
    setFormData(prev => ({
      ...prev,
      [e.target.name]: e.target.value
    }));
  };

  return (
    <div className="d-flex gap-2">
      <button className="btn btn-sm btn-accent" disabled>Payment Details</button>
      <form onSubmit={handleSubmit} className="d-flex gap-2">
        {!(formState.isSubmitted || formState.isSubmitting) && (
          <>
            <input
              type="text"
              name="file_number"
              value={formData.file_number}
              onChange={handleChange}
              className="form-control form-control-sm"
              style={{ width: '100px' }}
              placeholder="File #"
              required
            />
            <input
              type="text"
              name="payee"
              value={formData.payee}
              onChange={handleChange}
              className="form-control form-control-sm"
              style={{ width: '100px' }}
              placeholder="Pay to"
              required
            />
            <input
              type="text"
              name="recipient"
              value={formData.recipient}
              onChange={handleChange}
              className="form-control form-control-sm"
              style={{ width: '100px' }}
              placeholder="Mail to"
              required
            />
            <input
              type="text"
              name="mailing_address"
              value={formData.mailing_address}
              onChange={handleChange}
              className="form-control form-control-sm"
              style={{ width: '150px' }}
              placeholder="Mailing Address"
              required
            />
          </>
        )}
        <input
          type="submit"
          value={formState.isSubmitted ? "Submitted" : formState.isSubmitting ? "Submitting..." : "Submit"}
          className="btn btn-primary btn-sm"
          disabled={formState.isSubmitting || formState.isSubmitted}
        />
      </form>
    </div>
  );
};

export default SendPaymentDetails;