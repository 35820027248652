import React from 'react';

const Filters = ({ status, state, availableStates, onStatusChange, onStateChange }) => (
  <div className="mb-6 d-flex gap-3" role="group" aria-label="Filters">
    <div className="me-3">
      <input
        type="radio"
        className="btn-check"
        name="status"
        id="pending_action"
        value="pending_action"
        checked={status === 'pending_action'}
        onChange={(e) => onStatusChange(e.target.value)}
      />
      <label className="btn btn-outline-primary me-2" htmlFor="pending_action">
        Pending Collector Action
      </label>

      <input
        type="radio"
        className="btn-check"
        name="status"
        id="all"
        value="all"
        checked={status === 'all'}
        onChange={(e) => onStatusChange(e.target.value)}
      />
      <label className="btn btn-outline-primary" htmlFor="all">
        All Accounts
      </label>
    </div>

    <div className="dropdown d-inline-block ms-2">
      <label className="me-2">Lawsuit State:</label>
      <button className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown">
        {state || 'All States'}
      </button>
      <ul className="dropdown-menu" style={{ maxHeight: '300px', overflowY: 'auto' }}>
        <li>
          <button 
            className={`dropdown-item ${!state ? 'active' : ''}`}
            onClick={() => onStateChange('')}
          >
            All States
          </button>
        </li>
        {availableStates.map(stateOption => (
          <li key={stateOption}>
            <button
              className={`dropdown-item ${state === stateOption ? 'active' : ''}`}
              onClick={() => onStateChange(stateOption)}
            >
              {stateOption}
            </button>
          </li>
        ))}
      </ul>
    </div>
  </div>
);

export default Filters;
