import React from 'react';

const CurrencyInput = ({ value, onChange, ...props }) => {
  const [isFocused, setIsFocused] = React.useState(false);

  const formatCurrency = (value) => {
    if (!value) return '';
    const numberValue = parseFloat(value);
    if (isNaN(numberValue)) return '';

    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }).format(numberValue);
  };

  const handleChange = (e) => {
    let inputValue = e.target.value;
    // Remove everything except digits and decimal point
    const rawValue = inputValue.replace(/[^0-9.]/g, '');
    
    onChange({
      target: {
        name: e.target.name,
        value: rawValue
      }
    });
  };

  return (
    <input
      type="text"
      value={isFocused ? value : formatCurrency(value)}
      onChange={handleChange}
      onFocus={() => setIsFocused(true)}
      onBlur={() => setIsFocused(false)}
      {...props}
    />
  );
};

export default CurrencyInput;