import React, { useEffect, useState } from 'react';
import Layout from './Layout';

import { formatDollars } from '../../other/money';

import Filters from './accounts/Filters';
import AccountsTable from './accounts/AccountsTable';

const Accounts = () => {
  const [basicStats, setBasicStats] = useState(null);
  const [accountsData, setAccountsData] = useState(null);
  const [status, setStatus] = useState('pending_action');
  const [state, setState] = useState('');
  const [numPages, setNumPages] = useState(1);

  const fetchAccountData = async () => {
    const accountsDataResponse = await fetch(`/collectors/api/accounts_data?status=${status}&state=${state}&num_pages=${numPages}`, {
      headers: {
        'Accept': 'application/json'
      }
    });
    const accountsDataJson = await accountsDataResponse.json();
    setAccountsData(accountsDataJson);
  };

  const fetchBasicStats = async () => {
    const basicStatsResponse = await fetch(`/collectors/api/basic_stats`, {
      headers: {
        'Accept': 'application/json'
      }
    });
    const basicStatsJson = await basicStatsResponse.json();
    setBasicStats(basicStatsJson);
  };

  useEffect(() => {
    setAccountsData(null);
    fetchAccountData();
  }, [status, state, numPages]);

  useEffect(() => {
    fetchBasicStats();
  }, []);

  return (
    <Layout>
      <div>
        {basicStats && (
          <>
            <h2 className="font-serif display-4 mb-2">Hello {basicStats.law_firm.name},</h2>
            <p className="lead text-muted mb-0">
              Your firm has a total balance of <strong>{formatDollars(basicStats.total_balance.cents)}</strong> on SoloSettle.
            </p>
            <p className="lead text-muted mb-0">
              Consumers are notified that each communication is from a debt collector.
            </p>
            <br/>

            <h1 className="display-3 mb-6">Accounts</h1>

            <Filters
              status={status}
              state={state}
              availableStates={basicStats.available_states}
              onStatusChange={setStatus}
              onStateChange={setState}
            />
          </>
        )}

        {basicStats && !accountsData && (
          <div className="spinner-border" role="status"></div>
        )}
        {accountsData && (
          <>
            <AccountsTable 
              accounts={accountsData.customer_info}
              hasPaymentDetails={accountsData.has_payment_details}
              validFirstPaymentDate={accountsData.valid_first_payment_date}
            />

            {accountsData.more_pages && (
              <div className="text-center mt-4">
                <button onClick={() => setNumPages(prev => prev + 1)} className="btn btn-primary">
                  Load More
                </button>
              </div>
            )}

            <div className="mt-4 mb-4">
              Showing {accountsData.customer_info.length} out of {accountsData.total_count} matching accounts
            </div>
          </>
        )}
      </div>
    </Layout>
  );
};

export default Accounts;