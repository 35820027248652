import React from 'react';
import { formatDollars } from '../../../other/money';

const AccountDetails = ({ account: info }) => {
    const downloadAuthorizationLetter = async (event, documentId) => {
      event.preventDefault();
      const linkElement = event.target;
      const statusElement = document.getElementById(`download-status-${documentId}`);
      const errorElement = document.getElementById(`download-error-${documentId}`);
      
      errorElement.querySelector('span').textContent = '';
      errorElement.classList.add('d-none');
      
      linkElement.classList.add('d-none');
      statusElement.classList.remove('d-none');
      
      try {
        const response = await fetch('/collectors/api/render_document', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
          },
          body: JSON.stringify({ customer_document_id: documentId })
        });
        
        const data = await response.json();
        if (data.success) {
          window.open(data.link, '_blank');
        } else {
          throw new Error(data.error || 'Error downloading authorization letter');
        }
      } catch (error) {
        console.error('Error:', error);
        errorElement.querySelector('span').textContent = error.message;
        errorElement.classList.remove('d-none');
      } finally {
        statusElement.classList.add('d-none');
        linkElement.classList.remove('d-none');
      }
    };

    return (
      <div className="card-body m-3">
        <h2 className="mb-2">Account Information</h2>
        <p className="mb-1"><strong>Plaintiff Name:</strong> {info.plaintiff_name || 'n/a'}</p>
        <p className="mb-1"><strong>Lawsuit State:</strong> {info.lawsuit_state || 'n/a'}</p>
        <p className="mb-1"><strong>Consumer Name:</strong> {info.customer_name || 'n/a'}</p>
        <p className="mb-1"><strong>Reference Number:</strong> {info.civil_number || 'n/a'}</p>
        <p className="mb-1"><strong>Social Security Number:</strong> {info?.social_security_number?.social_security_number || (info?.social_security_number?.social_security_number_last_four ? `XXX-XX-${info.social_security_number.social_security_number_last_four}` : 'n/a')}</p>
        <p className="mb-1"><strong>Date of Birth:</strong> {info.birthdate}</p>
        <p className="mb-1">
          <strong>Address:</strong>{' '}
          {info.customer_mailing_address ? (
            `${info.customer_mailing_address.customer_street}, ${info.customer_mailing_address.customer_city}, ${info.customer_mailing_address.customer_state} ${info.customer_mailing_address.customer_zip_code}`
          ) : (
            'n/a'
          )}
        </p>
        <p className="mb-1"><strong>Solosettle Email:</strong> {info.email || 'n/a'}</p>
        <p className="mb-1">{info.lawsuit_exists ? 'Litigation Account' : 'Pre-litigation Account'}</p>
  
        {info.settlement_negotiation_events?.length > 0 && (
          <p className="mb-1">
            <a
              href="#"
              className="text-primary"
              id={`download-link-${info.customer_document.id}`}
              onClick={(e) => downloadAuthorizationLetter(e, info.customer_document.id)}
            >
              Download authorization letter
            </a>
            <div id={`download-status-${info.customer_document.id}`} className="d-none">
              <span className="text-dark">
                Downloading... <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              </span>
            </div>
            <div id={`download-error-${info.customer_document.id}`} className="d-none">
              <span className="text-danger"></span>
            </div>
          </p>
        )}
  
        <h3 className="mt-4 mb-2">Hardship Information</h3>
        {Object.entries(info.hardship || {})
          .filter(([_, value]) => value !== "0")
          .map(([key, value]) => (
            <p key={key} className="mb-1 ms-5">{value}</p>
          ))}
        
        {info.monthly_income_total && (
          <p className="mb-1 ms-5">
            Monthly Income: {formatDollars(info.monthly_income_total)}
          </p>
        )}
        
        {info.monthly_expenses_total && (
          <p className="mb-1 ms-5">
            Monthly Expenses: {formatDollars(info.monthly_expenses_total)}
          </p>
        )}
  
        <h3 className="mt-4 mb-2">History</h3>
        <div className="ms-5">
          <table className="table table-sm table-borderless">
            <thead>
              <tr>
                <th>Date</th>
                <th>Action</th>
                <th>Amount</th>
                <th>Payments</th>
                <th>First Payment</th>
              </tr>
            </thead>
            <tbody>
              {(info.settlement_negotiation_events || []).map((event, index) => (
                <tr key={index}>
                  <td>{new Date(event.created_at).toLocaleDateString()}</td>
                  <td>{`${event.party.charAt(0).toUpperCase() + event.party.slice(1)} ${event.action.charAt(0).toUpperCase() + event.action.slice(1)}`}</td>
                  <td>
                    {event.offers.length > 0 ? formatDollars(event.offers[0].total_amount_cents) : '-'}
                  </td>
                  <td>{event.offers[0]?.payments || '-'}</td>
                  <td>
                    {event.offers[0]?.first_payment_date ? 
                      event.offers[0].first_payment_date
                      : '-'}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    );
  };

export default AccountDetails;
