// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs';
import * as ActiveStorage from '@rails/activestorage';
import React from 'react'
import { createRoot } from 'react-dom/client'
import CollectorsDashboardRoot from './components/CollectorsDashboardRoot';

Rails.start();
ActiveStorage.start();

import "./controllers"

import 'theme/theme';

import 'other/document_editor';
import 'other/direct_uploads';
import 'other/stripe';
import 'other/document_download_link_polling';
import 'other/ssn_input_formatter';
import 'other/phone_number_input_formatter';
import 'other/commento';
import 'other/toc';

document.addEventListener('DOMContentLoaded', () => {
  const container = document.getElementById('react-collectors-dashboard-root')
  if (container) {
    const root = createRoot(container)
    root.render(<CollectorsDashboardRoot />)
  }
});
