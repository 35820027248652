import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const Login = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [errorMessage, setErrorMessage] = useState('');

  const handleLogin = async (authToken) => {
    try {
      const response = await fetch('/collectors/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ auth_token: authToken })
      });

      if (response.ok) {
        navigate('/collectors/accounts');
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'Invalid authentication code');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An error occurred during login. Please try again.');
    }
  };

  useEffect(() => {
    // First, see if we're already logged in
    const checkAuth = async () => {
      try {
        const response = await fetch('/collectors/api/check_auth', {
          headers: {
            'Accept': 'application/json'
          }
        });
        if (response.ok) {
          navigate('/collectors/accounts');
        }
      } catch (error) {
        console.error('Error checking authentication:', error);
      }
    };
    checkAuth();

    // If we're not logged in, check for an auth token in the URL
    const params = new URLSearchParams(location.search);
    const authToken = params.get('auth_token');
    if (authToken) {
      handleLogin(authToken);
    }
  }, [navigate, location]);  

  const handleSubmit = (e) => {
    e.preventDefault();
    const authToken = e.target.auth_token.value;
    handleLogin(authToken);
  };

  return (
    <div className="container mb-17">
      <h1>Enter the code received with the email sent by Solo.</h1>
      {errorMessage && (
        <div className="alert alert-danger" role="alert">
          {errorMessage}
        </div>
      )}
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <br />
          <input
            type="text"
            name="auth_token"
            className="form-control"
            placeholder="123ABC"
          />
        </div>
        <div className="form-group">
          <button type="submit" className="btn btn-primary">
            Log in
          </button>
        </div>
      </form>
    </div>
  );
};

export default Login;