// input, if int, is cents
// input, if string, is dollars
// output is a string that with dollar sign and commas
export function formatDollars(input) {
  let rawFloat;
  if (typeof input === 'string') {
    rawFloat = parseFloat(input.replace(/[^\d.]/g, ''));
  } else {
    rawFloat = (input / 100).toFixed(2);
  }
  
  // Convert to string and split into whole/decimal parts
  const [whole, decimal] = rawFloat.toString().split('.');
  
  // Add commas to whole number part using regex
  const withCommas = whole.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  
  // Combine with decimal part and add dollar sign
  return `$${withCommas}${decimal ? '.' + decimal : '.00'}`;
}

// $100.00 -> 10000
// $100 -> 10000
// 100 -> 10000
export function extractCentsFromDollarString(input) {
  if (typeof input === 'number') {
    return input * 100;
  }
  
  // Remove dollar sign and any commas
  const cleanedInput = input.replace(/[$,]/g, '');
  
  // Convert to float and multiply by 100 to get cents
  const dollars = parseFloat(cleanedInput);
  return Math.round(dollars * 100);
}