import React, { useState } from 'react';
import Layout from './Layout';

const DirectDeposit = () => {
  const [email, setEmail] = useState('');
  const [status, setStatus] = useState({ message: '', isError: false });

  const handleSubmit = async (e) => {
    e.preventDefault();
    setStatus({ message: '', isError: false });

    try {
      const response = await fetch('/collectors/api/digital_check_email', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
        },
        body: JSON.stringify({ email })
      });

      if (response.ok) {
        setStatus({ message: 'Email saved successfully!', isError: false });
        setEmail('');
      } else {
        const data = await response.json();
        setStatus({ message: data.message, isError: true });
      }
    } catch (error) {
      setStatus({ message: error.message, isError: true });
    }
  };

  return (
    <Layout>
      <div className="display-3 mb-4">Direct Deposit</div>
      <h2>Enter an email address to receive direct deposit instructions.</h2>
      <p className="lead text-muted mb-4">
        We'll email a digital check with the option to deposit it directly to your bank account.<br/>
        Most people use an email address for their accounting department.
      </p>
      
      <form onSubmit={handleSubmit} className="mt-4 w-50">
        {status.message && (
          <div className={`alert ${status.isError ? 'alert-danger' : 'alert-success'} mt-3`}>
            {status.message}
          </div>
        )}
        <div className="form-group">
          <input
            type="email"
            className="form-control form-control-lg"
            placeholder="accounting@collector.com"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>

        <button type="submit" className="btn btn-primary btn-lg mt-3">
          Set up direct deposit
        </button>
      </form>
    </Layout>
  );
};

export default DirectDeposit;