import React, { useState } from 'react';
import CurrencyInput from '../../../shared/CurrencyInput';

const AcceptOrCounteroffer = ({ account, validFirstPaymentDate }) => {
  const [selectedAction, setSelectedAction] = useState(
    account.latest_historical_offer?.first_payment_date >= validFirstPaymentDate 
      ? 'accept' 
      : 'counteroffer'
  );
  
  const [isDisabled, setIsDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState({
    total_amount: '',
    payments: '',
    first_payment_date: ''
  });

  const handleAcceptOffer = async (event) => {
    event.preventDefault();
    if (!confirm("Are you sure you want to accept this offer?")) {
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }

    const response = await fetch('/collectors/api/accept_offer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({ customer_document_id: account.customer_document.id })
    });
    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      alert(data.error || 'Error processing request');
    }
  };

  const handleCounterOffer = async (event) => {
    event.preventDefault();
    if (!confirm("Are you sure you want to submit this counteroffer?")) {
      setIsLoading(false);
      setIsDisabled(false);
      return;
    }

    setIsLoading(true);
    setIsDisabled(true);

    const response = await fetch('/collectors/api/create_counteroffer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content
      },
      body: JSON.stringify({
        customer_document_id: account.customer_document.id,
        total_amount: formData.total_amount,
        payments: formData.payments,
        first_payment_date: formData.first_payment_date
      })
    });

    const data = await response.json();
    if (data.success) {
      return data;
    } else {
      alert(data.error || 'Error processing request');
    }
  };
  
  // Add form change handler
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const onSubmit = selectedAction === 'accept' ? handleAcceptOffer : handleCounterOffer;

  return (
    <div className="d-flex gap-2">
      {!isDisabled && (
        <>
          <select
            id="select-action"
            className={`form-select form-select-sm ${selectedAction === 'accept' ? 'bg-success' : 'bg-warning'} text-white`}
            value={selectedAction}
            onChange={(e) => setSelectedAction(e.target.value)}
            style={{ width: 'auto' }}
            disabled={isLoading || isDisabled}
          >
            {account.latest_historical_offer?.first_payment_date >= validFirstPaymentDate && (
              <option value="accept">Accept</option>
            )}
            <option value="counteroffer">Counteroffer</option>
          </select>

          {selectedAction === 'counteroffer' && (
            <form onSubmit={handleCounterOffer} className="d-flex gap-2">
              <CurrencyInput
                name="total_amount"
                value={formData.total_amount}
                onChange={handleFormChange}
                className="form-control form-control-sm"
                style={{ width: '125px' }}
                placeholder="Total"
                required
              />
              <input
                type="number"
                name="payments"
                value={formData.payments}
                onChange={handleFormChange}
                className="form-control form-control-sm"
                style={{ width: '80px' }}
                placeholder="Pays"
                required
              />
              <input
                type="date"
                name="first_payment_date"
                value={formData.first_payment_date}
                onChange={handleFormChange}
                className="form-control form-control-sm"
                required
                min={validFirstPaymentDate}
              />
            </form>
          )}
        </>
      )}
      
      <button 
        className="btn btn-primary btn-sm"
        onClick={e => {
          setIsLoading(true);
          setIsDisabled(true);
          onSubmit(e)
            .catch(() => {
              setIsDisabled(false);
            })
            .finally(() => {
              setIsLoading(false);
            });
        }}
        disabled={isLoading || isDisabled}
      >
        {isLoading ? (
          <>
            <span className="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
            Submitting...
          </>
        ) : isDisabled ? 'Submitted' : 'Submit'}
      </button>
    </div>
  );
};

export default AcceptOrCounteroffer;