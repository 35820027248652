import React, { useState } from 'react';
import { formatDollars, extractCentsFromDollarString } from '../../../other/money';
import AccountDetails from './AccountDetails';

import AcceptOrCounteroffer from './actions/AcceptOrCounteroffer';
import SendPaymentDetails from './actions/SendPaymentDetails';
import UploadSettlementAgreement from './actions/UploadSettlementAgreement';

const AccountRow = ({ validFirstPaymentDate, hasPaymentDetails, account }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const isLastEventCustomerOffer = account.latest_event?.party == "customer" && ["offer", "counteroffer"].includes(account.latest_event.action);
  const isLastEventLawyerCounteroffer = account.latest_event?.party !== "customer" && account.latest_event.action == "counteroffer";
  const isLastEventConfusingResponseToOffer = account.latest_event?.action == "sent_confusing_response_to_offer";

  const isLastEventAccept = account.latest_event?.action == "accept";
  const isLastEventConfusingResponseToPaymentDetails = account.latest_event?.action == "sent_confusing_response_to_payment_details_request";

  const isLastEventSentSettlementAgreement = account.latest_event?.action == "sent_settlement_agreement";
  const isLastEventSentConfusingResponseToSettlementAgreement = account.latest_event?.action == "sent_confusing_response_to_settlement_agreement_request";
  const isLastEventSentPaymentDetails = account.latest_event?.action == "sent_payment_details";

  const shouldRequestPaymentDetails = (isLastEventAccept || isLastEventConfusingResponseToPaymentDetails) && !hasPaymentDetails;
  const shouldRequestSettlementAgreement = 
    ((isLastEventAccept || isLastEventConfusingResponseToPaymentDetails) && hasPaymentDetails) ||
    (isLastEventSentPaymentDetails || isLastEventSentConfusingResponseToSettlementAgreement);

  return (
    <>
      <tr id={`account_${account.id}`}>
        <td style={{ 
          position: 'sticky', 
          backgroundColor: 'white', 
          left: 0, 
          zIndex: 1, 
          maxWidth: '210px', 
          overflow: 'hidden', 
          textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap' 
        }}>
          <button 
            onClick={() => setIsExpanded(!isExpanded)}
            className="btn btn-link p-0"
          >
            {account.customer_name || 'n/a'}
          </button>
        </td>
        <td className="text-center" style={{ 
          overflow: 'hidden', 
          textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap' 
        }}>
          {account.civil_number || 'n/a'}
        </td>
        <td className="text-center">
          {account.lawsuit_amount && formatDollars(account.lawsuit_amount)}
        </td>
        <td className="text-center" style={{ 
          maxWidth: '120px', 
          overflow: 'hidden', 
          textOverflow: 'ellipsis', 
          whiteSpace: 'nowrap' 
        }}>
          {account.last_action || '-'}
        </td>
        <td className="text-center">
          {account.latest_event?.created_at && new Date(account.latest_event.created_at).toLocaleDateString()}
        </td>
        <td className="text-center">
          {account.latest_historical_offer?.total_amount_cents && 
            formatDollars(account.latest_historical_offer.total_amount_cents)}
        </td>
        <td className="text-center">
          {account.latest_historical_offer?.payments || '-'}
        </td>
        <td className="text-center">
          {account.latest_historical_offer?.total_amount_cents && account.lawsuit_amount && (
            `${Math.round((account.latest_historical_offer.total_amount_cents / extractCentsFromDollarString(account.lawsuit_amount)) * 100)}%`
          )}
        </td>
        <td className="text-center" style={{ whiteSpace: 'nowrap' }}>
          <div style={{ position: 'relative', display: 'inline-block' }}>
            {account.latest_historical_offer?.first_payment_date < validFirstPaymentDate && ["offer", "counteroffer", "sent_confusing_response_to_offer"].includes(account.latest_event?.action) && (
              <div className="tooltip-container" style={{ position: 'absolute', whiteSpace: 'normal' }}>
                <div className="tooltip-trigger">!</div>
                <div className="tooltip-content">
                  First payment date must be on or after {account.latest_historical_offer?.first_payment_date}. You'll need to counteroffer with a new payment date.
                </div>
              </div>
            )}
            <span style={{ marginLeft: '25px' }}>
              {account.latest_historical_offer?.first_payment_date}
            </span>
          </div>
        </td>
        <td>
          {(isLastEventCustomerOffer || isLastEventConfusingResponseToOffer) && <AcceptOrCounteroffer account={account} validFirstPaymentDate={validFirstPaymentDate} />}
          {isLastEventLawyerCounteroffer && <div>Waiting on customer response to counteroffer.</div>}
          
          {shouldRequestPaymentDetails && <SendPaymentDetails account={account} />}
          {shouldRequestSettlementAgreement && <UploadSettlementAgreement account={account} />}

          {(isLastEventSentSettlementAgreement && !account.paid) && <div>Settlement agreement uploaded, waiting on payment.</div>}
          {account.paid > 0 && <div className="btn btn-sm btn-success">Payment Made</div>}
        </td>
      </tr>
      {isExpanded && (
        <tr>
          <td colSpan={10} className="p-0">
            <AccountDetails account={account} />
          </td>
        </tr>
      )}
    </>
  );
};

export default AccountRow;