import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

  // *** Getters

  get $element() {
    return $(this.element)
  }

  // *** Lifecycle

  connect() {
    this.$element.on({
      keyup: (e) => {
        const $target = $(e.target)

        if (0 === $target.prop("selectionStart") && $target.val().length === $target.prop("selectionEnd")) {
          return;
        }

        this.formatCurrency($(e.target));
      },
      blur: (e) => {
        this.formatCurrency($(e.target), true);
      }
    });
  }

  // *** Helpers

  formatNumber(n) {
    // format number 1234567 to 1,234,567
    return n.replace(/\D/g, "").replace(/\B(?=(\d{3})+(?!\d))/g, ",")
  }

  formatCurrency(input, blur = false) {
    // appends $ to value, validates decimal side
    // and puts cursor back in right position.

    // get input value
    var input_val = input.val();

    // don't validate empty input
    if (input_val === "") { return; }

    // original length
    var original_len = input_val.length;

    // initial caret position 
    var caret_pos = input.prop("selectionStart");

    // check for decimal
    if (input_val.indexOf(".") >= 0) {
      var decimal_pos = input_val.indexOf(".");
      var left_side = input_val.substring(0, decimal_pos);
      var right_side = input_val.substring(decimal_pos);

      left_side = this.formatNumber(left_side);
      right_side = this.formatNumber(right_side);

      if (blur) {
        right_side += "00";
      }

      // Limit decimal to only 2 digits
      right_side = right_side.substring(0, 2);

      // join number by .
      input_val = "$" + left_side + "." + right_side;

    } else {
      // no decimal entered
      // add commas to number
      // remove all non-digits
      input_val = this.formatNumber(input_val);
      input_val = "$" + input_val;

      // final formatting
      if (blur) {
        input_val += ".00";
      }
    }

    // send updated string to input
    input.val(input_val);

    if (!blur) {
      // put caret back in the right position
      var updated_len = input_val.length;
      caret_pos = updated_len - original_len + caret_pos;
      input[0].setSelectionRange(caret_pos, caret_pos);
    }
  }
}