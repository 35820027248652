import React from 'react';
import AccountRow from './AccountRow';

const AccountsTable = ({ accounts, onLoadMore, hasMorePages, hasPaymentDetails, validFirstPaymentDate }) => (
  <>
    <div className="table-responsive" style={{ position: 'relative' }}>
      <table className="table table-sm" style={{ verticalAlign: 'middle' }}>
        <thead>
          <tr>
            <th scope="col" style={{ position: 'sticky', backgroundColor: 'white', left: 0, zIndex: 1, minWidth: '130px', width: '130px' }}>Name</th>
            <th scope="col" className="text-center">Reference</th>
            <th scope="col" className="text-center" style={{ minWidth: '100px' }}>Amount</th>
            <th scope="col" className="text-center" style={{ minWidth: '110px' }}>Status</th>
            <th scope="col" className="text-center" style={{ minWidth: '100px' }}>Status <br/> Date</th>
            <th scope="col" className="text-center" style={{ minWidth: '100px' }}>Offer <br/> Amount</th>
            <th scope="col" className="text-center" style={{ minWidth: '80px' }}># <br/> Pays</th>
            <th scope="col" className="text-center" style={{ minWidth: '80px' }}>%</th>
            <th scope="col" className="text-center" style={{ minWidth: '100px' }}>First <br/> Payment</th>
            <th scope="col" className="text-center" style={{ minWidth: '80px' }}>Action</th>
          </tr>
        </thead>
        <tbody className="small">
          {accounts.map(account => (
            <AccountRow key={account.id} validFirstPaymentDate={validFirstPaymentDate} hasPaymentDetails={hasPaymentDetails} account={account} />
          ))}
        </tbody>
      </table>
      
      {hasMorePages && (
        <div className="text-center mt-4">
          <button onClick={onLoadMore} className="btn btn-primary">
            Load More
          </button>
        </div>
      )}
    </div>
  </>
);

export default AccountsTable;
