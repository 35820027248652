import React from 'react';
import Sidebar from './Sidebar';

const Layout = ({ children }) => {
  return (
    <div className="container-fluid">
      <div className="row flex-nowrap mb-12">
        <div style={{ width: '125px' }} className="d-none d-md-block">
          <Sidebar />
        </div>

        <div style={{ width: 'calc(100% - 125px)', minWidth: '320px' }}>
          <div className="d-md-none">
            <Sidebar horizontal={true} />
          </div>
          
          {children}
        </div>
      </div>
    </div>
  );
};

export default Layout;