import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import PrivateRoute from './collectors_dashboard/PrivateRoute';
import Accounts from './collectors_dashboard/Accounts';
import DirectDeposit from './collectors_dashboard/DirectDeposit';
import PaymentDetails from './collectors_dashboard/PaymentDetails';
import Login from './collectors_dashboard/Login';

const CollectorsDashboardRoot = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route 
          path="/collectors"
          element={<Login />}
        />
        <Route
          path="/collectors/accounts"
          element={
            <PrivateRoute>
              <Accounts />
            </PrivateRoute>
          }
        />
        <Route
          path="/collectors/direct_deposit"
          element={
            <PrivateRoute>
              <DirectDeposit />
            </PrivateRoute>
          }
        />
        <Route
          path="/collectors/payment_details"
          element={
            <PrivateRoute>
              <PaymentDetails />
            </PrivateRoute>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};

export default CollectorsDashboardRoot;