import React, { useState } from 'react';

const UploadSettlementAgreement = ({ account }) => {
  const [file, setFile] = useState(null);
  const [isUploaded, setIsUploaded] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!confirm("Are you sure you want to submit?")) {
      return;
    }
    
    setIsSubmitting(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('customer_document_id', account.customer_document.id);

    try {
      const response = await fetch('/collectors/api/upload_settlement_agreement', {
        method: 'POST',
        headers: {
          'X-CSRF-Token': document.querySelector('[name="csrf-token"]').content,
        },
        body: formData,
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      
      setIsUploaded(true);
    } catch (error) {
      console.error('Error uploading settlement agreement:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  if (isUploaded) {
    return (
      <div className="d-flex gap-2">
        <input type="submit" value="Agreement Uploaded" className="btn btn-primary btn-sm" disabled />
      </div>
    );
  }

  return (
    <div className="d-flex gap-2">
      <button className="btn btn-sm btn-accent" disabled>
        Upload Settlement Agreement
      </button>
      
      <form onSubmit={handleSubmit} className="d-flex gap-2">
        <div className="custom-file-input d-flex align-items-center">
          <input
            type="file"
            name="settlement_agreement"
            accept="application/pdf"
            required
            className="form-control-file"
            style={{ color: 'black', border: 'none', background: 'none', width: '250px' }}
            onChange={handleFileChange}
            disabled={isSubmitting}
          />
        </div>
        <input
          type="submit"
          value={isSubmitting ? 'Submitting...' : 'Submit'}
          className="btn btn-primary btn-sm"
          disabled={!file || isSubmitting}
        />
      </form>
    </div>
  );
};

export default UploadSettlementAgreement;