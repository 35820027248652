document.addEventListener("DOMContentLoaded", function() {
    if (document.getElementById('document-editor')) {
        const quill = new Quill('#document-editor', {
            theme: 'snow',
            modules: {
                toolbar: { container: '#toolbar' }
            }
        });

        document.getElementById('undo').onclick = function() {
            quill.history.undo();
        };
        document.getElementById('redo').onclick = function() {
            quill.history.redo();
        };

        const savedContent = document.querySelector('#hidden-content').value;
        if (savedContent) {
            quill.root.innerHTML = savedContent;
        }

        document.querySelector('#editor-form').addEventListener('submit', function() {
            const quillHtmlContent = quill.root.innerHTML;
            document.querySelector('#hidden-content').value = quillHtmlContent;
        });

        document.getElementById('preview-button').addEventListener('click', function() {
            // wrap with ql-editor to reproduce exactly what we see in the editor
            const quillHtmlContent = `<div class='ql-container'><div class='ql-editor'>${quill.root.innerHTML}</div></div>`;

            html2pdf().set({
                margin: 1,
                filename: 'document-preview.pdf',
                image: { type: 'jpeg', quality: 0.8 },
                jsPDF: { unit: 'in', format: 'letter', orientation: 'portrait' } // 8.5 x 11 inches
            }).from(quillHtmlContent).toPdf().output('blob').then(function(pdfBlob) {
                const pdfUrl = URL.createObjectURL(pdfBlob);
                document.getElementById('pdf-viewer-frame').src = pdfUrl;
                document.getElementById('export-pdf-modal').style.display = "block";
            });
        });

        document.getElementById('solosuit-close-modal').onclick = function() {
            document.getElementById('export-pdf-modal').style.display = "none";
        };

        window.onclick = function(event) {
            if (event.target === document.getElementById('export-pdf-modal')) {
                document.getElementById('export-pdf-modal').style.display = "none";
            }
        };
    }
});